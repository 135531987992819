/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

            $(window).load(function() {
    	        $('.bxslider').bxSlider({
    	        mode: 'fade',
    	        captions: false,
    	        pager: true,
    	        pagerType: 'short',
    	        controls: true,
    	        pagerShortSeparator: ' av ',
    	        nextText: '<i class="fa fa-angle-right fa-5x"></i>',
    	        prevText: '<i class="fa fa-angle-left fa-5x"></i>',
    	        auto: false,
    	        pause: 5000,
    	        adaptiveHeight: true,
    	        speed: 700,
    	        onSliderLoad: function(){
    	            $(".bxslider").css("visibility", "visible");

    	            
    	          },

    	        onSlideBefore: function ($slideElement, oldIndex, newIndex) {
    	            
    	            $('.active-slide').removeClass('active-slide');
    	            
    	        },

    	        onSlideAfter: function ($slideElement, oldIndex, newIndex) {
    	            $('.bxslider>li').eq(newIndex).addClass('active-slide');
    	        },
    	      
    	        });
          });
		
		$(document).ready(function() {

			$('.collapse').collapse('hide');

			$('.collapse').collapse({
  				toggle: false
			})

			// $('.collapse').on('show.bs.collapse hide.bs.collapse', function(e) {
		 //        e.preventDefault();
		 //    });

		 //    $('[data-toggle="collapse"]').on('click', function(e) {
		 //        e.preventDefault();
		 //        $($(this).data('target')).toggleClass('in');
		 //    });
		});

		
        // if(!Modernizr.input.placeholder){

        //     $('[placeholder]').focus(function() {
        //       var input = $(this);
        //       if (input.val() === input.attr('placeholder')) {
        //         input.val('');
        //         input.removeClass('placeholder');
        //       }
        //     }).blur(function() {
        //       var input = $(this);
        //       if (input.val() === '' || input.val() === input.attr('placeholder')) {
        //         input.addClass('placeholder');
        //         input.val(input.attr('placeholder'));
        //       }
        //     }).blur();
        //     $('[placeholder]').parents('form').submit(function() {
        //       $(this).find('[placeholder]').each(function() {
        //         var input = $(this);
        //         if (input.val() === input.attr('placeholder')) {
        //           //input.val('');
        //         }
        //       });
        //     });

        // }

        // if (!Modernizr.svg) {
        //     var imgs = document.getElementsByTagName('img');
        //     for (var i = 0; i < imgs.length; i++) {
        //         if (/.*\.svg$/.test(imgs[i].src)) {
        //             imgs[i].src = imgs[i].src.slice(0, -3) + 'png';
        //         }
        //     }
        // }

        // $('input, textarea').placeholder();

        $(document).ready(function() {

            // $('#kontaktform').bootstrapValidator({
            //     //submitButtons: ".nej",
            //     feedbackIcons: {
            //         valid: 'glyphicon glyphicon-ok',
            //         invalid: 'glyphicon glyphicon-remove',
            //         validating: 'glyphicon glyphicon-refresh'
            //     },
            //     fields: {
            //         lastname: {
            //             feedbackIcons: true,
            //             validators: {
            //                 notEmpty: {
            //                     message: 'Skriv in ditt efternamn',
            //                 },
            //             }
            //         },

            //         forename: {
            //             feedbackIcons: true,
            //             validators: {
            //                 notEmpty: {
            //                     message: 'Skriv in ditt förnamn',
            //                 },
            //             }
            //         },
            //         personnr: {
            //             feedbackIcons: true,
            //             validators: {
            //                 notEmpty: {
            //                     message: 'Skriv in ditt personnummer',
            //                 },
            //             }
            //         },
            //         email: {
            //             feedbackIcons: true,
            //             validators: {
            //                 notEmpty: {
            //                     message: 'Skriv in din E-post',
            //                 },
            //                 emailAddress: {
            //                     message: 'Fyll i en korrekt E-post adress'
            //                 }
            //             }
            //         },
                 
            //       }
            // });

               
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },

    'kontakt': {
    	init: function() {
        // JavaScript to be fired on the home page

	        function initialize() {

	        	var myLatLng = new google.maps.LatLng(57.7207725, 11.9764661);
		        var mapCanvas = document.getElementById('map-canvas');

		        var mapOptions = {
					center: myLatLng,
					zoom: 13,
					mapTypeId: google.maps.MapTypeId.ROADMAP
				};

		      	var map = new google.maps.Map(mapCanvas, mapOptions);
				var marker = new google.maps.Marker({
					position: myLatLng,
			   		title:"Båtakuten", 
			  		url: 'http://goo.gl/PxFDvH'
				});

				// To add the marker to the map, call setMap();
				marker.setMap(map);
				google.maps.event.addListener(marker, 'click', function() {
					window.open(
					  this.url,
					  '_blank' // <- This is what makes it open in a new window.
					);

					

				});
		      	
		  	}
		  	google.maps.event.addDomListener(window, 'load', initialize);
    	},

      	finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      	}
    },
    // About us page, note the change from about-us to about_us.
    'batar_till_salu': {
      init: function() {
        // JavaScript to be fired on the about us page
        /* FILTER BOX */
        var selectedTyp = '';
        var termSlug = '';



      	function filterProducts() {

       		//console.log(selectedBadd);
       		//console.log(selectedBrand);

           $('.grid').addClass('loading');
           $('.fa-spin').css('opacity', '1');
           
           $.get(ajaxfilter.ajaxurl,{

           	action:'custom_ajax_filter', 
           	selectedtyp: selectedTyp,

           	}, function(data){

             $('.grid').empty().append( data );
             $('.fa-spin').css('opacity', '0');
             $('.grid').removeClass('loading');
                       
           });

        }

        function changeFilter(filter){

        	// var check = '<i class="fa fa-check"></i>';

			$('#typ_filter span.active').removeClass('active');
			$('#typ_filter span[data-filter="'+filter+'"]').addClass('active');

			$('#typ_filter span.selected').removeClass('selected');
			$('#typ_filter span[data-filter="'+filter+'"]').addClass('selected');

			            
			selectedCat = '';
			selectedTyp = filter;

			          
			// console.log(termSlug);


			filterProducts();

        }

        $("#typ_filter span").click(function() {

        	var thisSlug = $(this).attr('data-filter');

        	console.log(thisSlug);

           	changeFilter(thisSlug);

        });
       
      }
    }

  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
